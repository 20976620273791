import { useMemo } from 'react'

import { captureException } from '@sentry/nextjs'

import { PostgrestError } from '@supabase/supabase-js'

import { useAuthContext } from '@/context/AuthContext'

import {
    ExtendedReservationUpdateT,
    ReservationInsertT,
    ReservationT,
    ReservationUpdateT,
    TableT,
} from '@/types/globalTypes'

const useBookingServiceApi = () => {
    const { supabaseClient } = useAuthContext()

    const getTables = async (startDate: string, endDate: string) => {
        const { data, error } = await supabaseClient
            .from('venue_tables')
            .select()
            .gte('table_date', startDate)
            .lte('table_date', endDate)

        if (error) {
            captureException(error)
        }

        return { tables: data, tableError: error }
    }

    const getSpecificTable = async (tableId: string) => {
        let { data, error } = await supabaseClient
            .from('venue_tables')
            .select('*')
            .eq('id', tableId)
            .single()

        if (error) {
            captureException(error)
        }

        return { data, error }
    }
    const getSpecificStaticTable = async (tableId: string, startDate: string, endDate: string) => {
        let { data, error } = await supabaseClient
            .from('venue_tables')
            .select('*')
            .eq('static_reservation_id', tableId)
            .gte('table_date', startDate)
            .lte('table_date', endDate)
            .limit(1)

        if (error) {
            captureException(error)
        }

        return { data, error }
    }

    const copyDefaultTableLayout = async (date: string) => {
        const { data, error } = await supabaseClient.rpc('create_tables_from_layout_for_date', {
            specific_date: date,
        })

        if (error) {
            captureException(error)
        }

        return { data, error }
    }

    const generateQrCode = async (tableId: string, pageMode: string, venueId: string) => {
        const { data, error } = await supabaseClient.functions.invoke('generate-qr-code', {
            body: {
                tableId,
                pageMode,
                venueId
            },
        })

        if (error) {
            captureException(error)
        }

        return [data, error]
    }
    const generateNewPinCode = async (static_reservation_id: string) => {
        const { data, error } = await supabaseClient.functions.invoke('generate-pin-code', {
            body: {
                static_reservation_id,
            },
        })

        if (error) {
            captureException(error)
        }

        return [data, error]
    }

    const createTable = async (tableData: Partial<TableT>) => {
        const { data, error } = await supabaseClient
            .from('venue_tables')
            .insert({ ...tableData, id: tableData.id, venue_id: tableData.venue_id })
            .select()
        if (error) {
            captureException(error)
        }

        return [data, error]
    }

    const updateTable = async (tableData: TableT) => {
        const { data, error } = await supabaseClient
            .from('venue_tables')
            .update({ ...tableData })
            .eq('id', tableData.id)
            .select()
        if (error) {
            captureException(error)
        }

        return [data, error]
    }
    const deleteTable = async (tableId: string) => {
        const { error } = await supabaseClient.from('venue_tables').delete().eq('id', tableId)
        if (error) {
            captureException(error)
        }

        return error
    }

    const getReservationById = async (id: string) => {
        const { data, error } = await supabaseClient
            .from('reservations')
            .select('*')
            .eq('id', id)
            .limit(1)
        if (error) {
            captureException(error)
        }

        return { data, error }
    }
    const getLimitedReservationData = async (id: string) => {
        const { data, error } = await supabaseClient
            .from('safe_reservations')
            .select('*')
            .eq('id', id)
            .limit(1)
        if (error) {
            captureException(error)
        }

        return { data, error }
    }

    const getReservationByMultipleIds = async (ids: string[]) => {
        const { data, error } = await supabaseClient.from('reservations').select('*').in('id', ids)
        if (error) {
            captureException(error)
        }

        return [data, error]
    }

    const getReservationsByDateRange: (
        startDate: string,
        endDate: string
    ) => Promise<{
        reservationData: ReservationT[]
        reservationError: PostgrestError | null
    }> = async (startDate, endDate) => {
        let { data: dateRangeData, error: dateRangeError } = await supabaseClient
            .from('reservations')
            .select(
                `
                *,
                table:table(*),
                pin_codes:pin_codes(pin_code)
            `
            )
            .gte('date_of_reservation', startDate)
            .lte('date_of_reservation', endDate)
            .is('is_static', false)

        let { data: staticData, error: staticError } = await supabaseClient
            .from('reservations')
            .select(
                `
                *,

                pin_codes:pin_codes(pin_code)
            `
            )
            .is('is_static', true)

        if (dateRangeError || staticError) {
            captureException(dateRangeError || staticError)
            return { reservationData: null, reservationError: dateRangeError || staticError }
        }

        const combinedData = [...dateRangeData, ...staticData]

        // Map over the combined data to flatten the structure
        const flattenedData: (ReservationT & { pin_code: string })[] = combinedData.map(
            (reservation) => {
                const { pin_codes, table, ...rest } = reservation
                return {
                    ...rest,
                    table,
                    pin_code: pin_codes?.[0]?.pin_code,
                }
            }
        )

        return { reservationData: flattenedData, reservationError: null }
    }

    const getPinCodeByReservationId = async (reservationId: string) => {
        let { data, error } = await supabaseClient
            .from('reservations')
            .select(
                `
                pin_codes:pin_codes(pin_code)
            `
            )
            .eq('id', reservationId)
        if (error) {
            captureException(error)
            return { data: null, error }
        }

        const pinCode = data?.[0]?.pin_codes?.[0]?.pin_code
        return { pinCode, error }
    }

    const createReservation = async (reservationData: ReservationInsertT) => {
        const { error, data } = await supabaseClient
            .from('reservations')
            .insert({ ...reservationData })
            .select()
        if (error) {
            captureException(error)
        }

        return [error, data]
    }
    const createReservationWithoutSelect = async (reservationData: ReservationT) => {
        const { error, data } = await supabaseClient
            .from('reservations')
            .insert({ ...reservationData })
        if (error) {
            captureException(error)
        }

        return [error, data]
    }

    const updateReservation = async (reservation: ExtendedReservationUpdateT) => {
        const { hasPendingOrder, pin_code, table, ...rest } = reservation

        const { data, error } = await supabaseClient
            .from('reservations')
            .update(rest as ReservationUpdateT)
            .eq('id', reservation.id)
            .select()
        if (error) {
            captureException(error)
        }

        return { data, error }
    }

    const removeTableFromReservation = async (reservationId: string) => {
        const { error } = await supabaseClient
            .from('reservations')
            .update({ table: null })
            .eq('id', reservationId)
        if (error) {
            captureException(error)
        }

        return error
    }

    const deleteReservation = async (reservation: ReservationT) => {
        const { error } = await supabaseClient
            .from('reservations')
            .delete()
            .eq('id', reservation.id)
        if (error) {
            captureException(error)
        }

        return error
    }

    const deleteMultipleReservations = async (idsToDelete: string[]) => {
        const { error } = await supabaseClient.from('reservations').delete().in('id', idsToDelete)
        if (error) {
            captureException(error)
        }

        return error
    }

    const getEventsByDateRange = async (startDate: string, endDate: string, locale: string, venueId: string) => {
        const { data, error } = await supabaseClient.rpc('get_events_by_date_range', {
            p_start_date: startDate,
            p_end_date: endDate,
            p_locale: locale,
            p_venue_id: venueId
        })
        if (error) {
            captureException(error)
            console.error('Error fetching events:', error)
            return { eventsData: null, eventError: error }
        }

        return { eventsData: data, eventError: error }
    }

    const verify_reservation_pin_code = async (reservationId: string, pinCode: string) => {
        /* We provide the pin code and if it's correct we create and return valid token which holds the reservation id and role of the customer */
        let { data, error } = await supabaseClient.rpc('verify_reservation_pin_code', {
            p_reservation_id: String(reservationId),
            p_pin_code: String(pinCode),
        })
        if (error) {
            captureException(error)
        }
        return [data, error]
    }

    const getReservationCountPerDay = async (start_date: string, end_date: string) => {
        const { data, error } = await supabaseClient.rpc('get_reservation_count_per_day', {
            p_start_date: start_date,
            p_end_date: end_date,
        })
        if (error) {
            captureException(error)
            return { data: null, error }
        }
        return { data, error }
    }

    const objectApi = useMemo(
        () => ({
            copyDefaultTableLayout,
            getTables,
            createTable,
            updateTable,
            deleteTable,
            getSpecificTable,
            getSpecificStaticTable,
            generateQrCode,
            generateNewPinCode,
            verify_reservation_pin_code,
            createReservation,
            createReservationWithoutSelect,
            updateReservation,
            deleteReservation,
            deleteMultipleReservations,

            removeTableFromReservation,

            getReservationsByDateRange,
            getReservationById,
            getLimitedReservationData,
            getPinCodeByReservationId,
            getReservationByMultipleIds,
            getEventsByDateRange,
            getReservationCountPerDay,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [supabaseClient]
    )

    return objectApi
}

export default useBookingServiceApi
